import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../../sass/landing/CtaBtn.scss'
import { faSignIn } from '@fortawesome/free-solid-svg-icons'
import { useTranslations } from 'next-intl'
import { Link } from '@/i18n/routing'

interface Props {
  text?: string;
  urlText?: string;
  withIcon?: boolean
}

const CtaBtn = ({ text, urlText, withIcon = true }: Props) => {
  const t = useTranslations('Common')
  return (
    <Link href='/login/signup' className='cta-btn'>
      <span>{text ?? t('startNow')}</span>
      {withIcon &&
        <div className='whatsapp'>
          <FontAwesomeIcon icon={faSignIn} />
        </div>}
    </Link>
  )
}
export default CtaBtn
