'use client'

import Image from 'next/image'
import '../../sass/landing/OurCustomers.scss'
import { variants } from './framer-motion-animation'
import { motion, useInView } from 'framer-motion'
import { useRef } from 'react'
import { useTranslations } from 'next-intl' // Importa el hook useTranslations

const OurCustomers = () => {
  const ref = useRef(null)
  const isInView = useInView(ref, { once: true })
  const t = useTranslations('OurCustomers') // Obtiene las traducciones para OurCustomers

  const logos = [
    { src: '/img/landing/customers-logos/premier2.webp', alt: t('logosAlt.premier') },
    { src: '/img/landing/customers-logos/Tabanco.webp', alt: t('logosAlt.tabanco') },
    { src: '/img/landing/customers-logos/iturrialde.webp', alt: t('logosAlt.iturrialde') },
    { src: '/img/landing/customers-logos/lucky.webp', alt: t('logosAlt.lucky') },
    { src: '/img/landing/customers-logos/unter.webp', alt: t('logosAlt.unter') },
    { src: '/img/landing/customers-logos/victoria.webp', alt: t('logosAlt.victoria') },
    { src: '/img/landing/customers-logos/vermu.webp', alt: t('logosAlt.vermu') },
    { src: '/img/landing/customers-logos/masai.webp', alt: t('logosAlt.masai') },
    { src: '/img/landing/customers-logos/shanao.webp', alt: t('logosAlt.shanao') },
    { src: '/img/landing/customers-logos/gottan.webp', alt: t('logosAlt.gottan') }
  ]

  return (
    <motion.div
      ref={ref}
      initial='hidden'
      animate={isInView ? 'visible' : 'hidden'}
      transition={{ duration: 0.5 }}
      variants={variants}
    >
      <section style={{ marginTop: '70px' }} className='customers-section'>
        <header>
          <span className='chip'>{t('chip')}</span>
          <h2>{t('heading')}</h2>
          <p>{t('description')}</p>
        </header>
        <div className='logos-container'>
          {logos.map((logo, index) => (
            <Image
              key={index}
              src={logo.src}
              alt={logo.alt}
              height={200}
              width={300}
            />
          ))}
        </div>
      </section>
    </motion.div>
  )
}
export default OurCustomers
