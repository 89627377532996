'use client'

import Image from 'next/image'
import '../../sass/landing/LandingHero.scss'
import CtaBtn from '@/components/landingPage/CtaBtn'
import { variants } from './framer-motion-animation'
import { motion, useInView } from 'framer-motion'
import { useRef } from 'react'
import { useTranslations } from 'next-intl'

const DashboardPeek = () => {
  const ref = useRef(null)
  const isInView = useInView(ref, { once: true })
  const t = useTranslations('DashboardPeek')
  return (
    <motion.div
      ref={ref}
      initial='hidden'
      animate={isInView ? 'visible' : 'hidden'}
      transition={{ duration: 0.5 }}
      variants={variants}
    >
      <section style={{ marginTop: '70px' }} className='hero'>
        <div className='text-area'>
          <div className='btn-container'>
            <span className='chip'>{t('chip')}</span>
          </div>
          <h2>{t('heading')}</h2>
          <p>{t('description')}</p>
          <div className='btn-container'>
            <CtaBtn />
          </div>
        </div>
        <div className='img-container '>
          <Image
            style={{ maxWidth: '450px' }}
            className='with-border'
            src='/img/landing/dashboard.webp'
            alt={t('imageAlt')}
            height={800}
            width={800}
          />
        </div>
      </section>
    </motion.div>
  )
}
export default DashboardPeek
