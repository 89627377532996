'use client'

import Image from 'next/image'
import '../../sass/landing/LandingHero.scss'
import CtaBtn from '@/components/landingPage/CtaBtn'
import { useTranslations } from 'next-intl' // Importa el hook useTranslations
import Link from 'next/link'

const MenuHeroSection = () => {
  const t = useTranslations('MenuHeroSection') // Obtiene las traducciones para MenuHeroSection

  return (
    <section id='menu' className='hero'>
      <div className='text-area'>
        <h1>
          {t('title')} <span className='free'>{t('free')}</span>
        </h1>

        <p>{t('description')}</p>
        <div className='btn-container'>
          <CtaBtn />
          <Link className='demo-btn' target='_blank' href='/menu/barcraft'>
            {t('demoButton')}
          </Link>
        </div>
      </div>
      <div className='img-container'>
        <Link href='/menu/barcraft' target='_blank'>
          <Image
            alt={t('imageAlt')}
            priority
            src='/img/landing/menu-phone.webp'
            height={800}
            width={500}
          />
        </Link>
      </div>
    </section>
  )
}

export default MenuHeroSection
