'use client'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../../sass/landing/Features.scss'
import { faCalendar, faCheckDouble, faClock, faSackDollar, faThumbsUp, faUser } from '@fortawesome/free-solid-svg-icons'
import { variants } from './framer-motion-animation'
import { motion, useInView } from 'framer-motion'
import { useRef } from 'react'
import { useTranslations } from 'next-intl' // Importa el hook useTranslations

function BookingsFeatures () {
  const ref = useRef(null)
  const isInView = useInView(ref, { once: true })
  const t = useTranslations('BookingsFeatures') // Obtiene las traducciones para BookingsFeatures

  const features = [
    {
      icon: faThumbsUp,
      title: t('features.feature1.title'),
      description: t('features.feature1.description')
    },
    {
      icon: faClock,
      title: t('features.feature2.title'),
      description: t('features.feature2.description')
    },
    {
      icon: faCheckDouble,
      title: t('features.feature3.title'),
      description: t('features.feature3.description')
    },
    {
      icon: faCalendar,
      title: t('features.feature4.title'),
      description: t('features.feature4.description')
    },
    {
      icon: faSackDollar,
      title: t('features.feature5.title'),
      description: t('features.feature5.description')
    },
    {
      icon: faUser,
      title: t('features.feature6.title'),
      description: t('features.feature6.description')
    }
  ]

  return (
    <motion.div
      ref={ref}
      initial='hidden'
      animate={isInView ? 'visible' : 'hidden'}
      transition={{ duration: 0.5 }}
      variants={variants}
    >
      <section id='features' className='feature-section'>
        <span className='chip-bookings' style={{ margin: 'auto' }}>{t('chip')}</span>
        <h2>
          {t.rich('heading', {
            span: (chunks) => <span>{chunks}</span>
          })}
        </h2>
        <p>{t('description')}</p>

        <div className='feature-item-container'>
          {features.map((feature, index) => (
            <div className='feature-item' key={index}>
              <div className='icon-container'>
                <FontAwesomeIcon icon={feature.icon} />
              </div>
              <h5>{feature.title}</h5>
              <p>{feature.description}</p>
            </div>
          ))}
        </div>
      </section>
    </motion.div>
  )
}
export default BookingsFeatures
