'use client'

import React, { useRef } from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import '../../sass/landing/FAQ.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { N_FREE_ITEMS } from '@/config'
import { variants } from './framer-motion-animation'
import { motion, useInView } from 'framer-motion'
import { useTranslations } from 'next-intl'

const Faq = () => {
  const ref = useRef(null)
  const isInView = useInView(ref, { once: true })
  const t = useTranslations('Faq')

  // Definir una lista de IDs de preguntas
  const questionIds = ['q1', 'q2', 'q3', 'q4', 'q5', 'q6', 'q7', 'q8', 'q9', 'q10']

  return (
    <motion.div
      ref={ref}
      initial='hidden'
      animate={isInView ? 'visible' : 'hidden'}
      transition={{ duration: 0.5 }}
      variants={variants}
    >
      <section id='faq' style={{ marginTop: '70px' }} className='faq-section'>
        <span className='chip' style={{ margin: 'auto' }}>{t('chip')}</span>
        <h2>{t('heading')}</h2>

        {questionIds.map((id) => (
          <Accordion key={id} className='accordion'>
            <AccordionSummary
              expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
              aria-controls={`panel-${id}-content`}
              id={`panel-${id}-header`}
            >
              <p className='question'>
                {t(`questions.${id}.question`)}
              </p>
            </AccordionSummary>
            <AccordionDetails>
              <p className='answer'>
                {id === 'q3'
                  ? t(`questions.${id}.answer`, { N_FREE_ITEMS })
                  : t(`questions.${id}.answer`)}
              </p>
            </AccordionDetails>
          </Accordion>
        ))}

      </section>
    </motion.div>
  )
}

export default Faq
